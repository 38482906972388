export const ServerConfig = {
/*   imgBaseUrl: 'http://localhost:8000/stg/',
  apiBaseUrl: 'http://localhost:8000/api/',
  webBaseUrl: 'http://localhost:8000/',
 */
  
  imgBaseUrl: 'https://back.temp.vieira.bitmo.com.br/stg/',
  apiBaseUrl: 'https://back.temp.vieira.bitmo.com.br/api/',
  webBaseUrl: 'https://back.temp.vieira.bitmo.com.br/',



};

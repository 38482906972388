<div class="modal-header">
  <h4 class="modal-title" id="modal-title">Precisamos de sua confirmação</h4>
  <button
    type="button"
    class="close"
    aria-label="Botão para fechar"
    aria-describedby="modal-title"
    (click)="modal.dismiss('Cross click')"
  >
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <div [innerHTML]="description1"></div>
  <div [innerHTML]="description2"></div>
</div>
<div class="modal-footer">
  <button
    type="button"
    class="btn btn-outline-secondary"
    (click)="modal.dismiss('cancel click')"
  >
    Voltar
  </button>
  <button
    type="button"
    ngbAutofocus
    class="btn btn-danger"
    (click)="modal.close(true)"
  >
    Ok
  </button>
</div>


import { Permission } from 'src/app/panel/acl/permissions/permissions';
import { User } from '../models/user';

export const defaultPersonImage = '/assets/default-images/360_F_410657632_VgcV9gaH9AzGuMZgsEtsmkuLaeuiaBsw.webp';
export var UserConfig: User = {
    name: 'Admin',
    registration: 1,
    avatar: defaultPersonImage,
    permissions: [],
    id: 1,

};

export const UserPermissions: Permission[] = [];